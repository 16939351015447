export const codeSnippets = [
    {
      id: "style-1",
      title: "1.1",
      image: "/assets/style1.png",
      themes: ["premier-1", "premier-2"],
      category: "text",
      codeblock: [
        {
          title: "Narrow",
          code: `<div class='additional-style additional-style-1 clearfix'>
          <div class='additional-style-wrap'>
              <div class='additional-style-inner'>
                  <h2>Looking to quickly sell your car online? You&rsquo;ve come to the right place!</h2>
                  <p>If you&rsquo;re wondering how to go about selling your car, we&rsquo;re here to help you find the ideal way to maximise the return for your current vehicle.</p><a class='button' href='PUT_LINK_HERE'>CTA</a></div></div>
          </div>`
        },
        {
          title: "Full Width",
          content: "Add to Custom CSS",
          code: `.additional-style.additional-style-2 .additional-style-inner {
            width: 100%;
            }`
        },
        {
          title: "Background Colour",
          code: `<div class="additional-style additional-style-2 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <h2>Looking to quickly sell your car online? You&rsquo;ve come to the right place!</h2>
                  <p>If you&rsquo;re wondering how to go about selling your car, we&rsquo;re here to help you find the ideal way to maximise the return for your current vehicle.</p><a class="button" href="PUT_LINK_HERE">CTA</a></div></div>
          </div>
          `
        }
      ]
    },
    {
      id: "style-3",
      title: "2.1",
      image: "/assets/style3.png",
      themes: ["all"],
      category: "images",
      codeblock: [
        {
          title: "Without Links",
          code: `<div class="additional-style additional-style-3 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <ul>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                  </ul>
              </div></div></div>
      `
        },
        {
          title: "With Links",
          code: `<div class="additional-style additional-style-3 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <ul>
                      <li><a href="PUT_LINK_HERE"><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></a></li>
                      <li><a href="PUT_LINK_HERE"><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></a></li>
      <li><a href="PUT_LINK_HERE"><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></a></li>
      <li><a href="PUT_LINK_HERE"><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></a></li>
      <li><a href="PUT_LINK_HERE"><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></a></li>
                  </ul>
              </div></div></div>`
        },
        {
          title: "With Text (no links)",
          code: `<div class="additional-style additional-style-3 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-text">
                   <p>We really value your feedback, therefore we’d be super grateful if you could take a minute to leave us a review!</p>
                  </div>
                  <ul>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                  </ul>
              </div></div></div>`
        }
      ]
    },
    {
      id: "style-4",
      image: "/assets/style4.png",
      themes: ["all"],
      category: "archive",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-4 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-text">
                      <h3><strong>Every Vehicle Comes With</strong></h3>
                  </div>
                  <ul>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/icon-checkbox.png" class="fr-fic fr-dii" data-id="13501">
                          <p>12 Month MOT</p>
                      </li>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/icon-checkbox.png" class="fr-fic fr-dii" data-id="13501">
                          <p>12 Month MOT</p>
                      </li>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/icon-checkbox.png" class="fr-fic fr-dii" data-id="13501">
                          <p>12 Month MOT</p>
                      </li>
                  </ul><a class="button" href="PUT_LINK_HERE">CTA</a></div></div></div>
      `
        }
      ]
    },
    {
      id: "style-5",
      image: "/assets/style5.png",
      themes: ["all"],
      category: "archive",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-5 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-text">
                      <h3><strong>Every Vehicle Comes With</strong></h3>
                  </div>
                  <ul>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/icon-checkbox.png" class="fr-fic fr-dib" data-id="13501">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam varius sem tortor, nec sodales mi lacinia sit amet.</p>
                      </li>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/icon-checkbox.png" class="fr-fic fr-dib" data-id="13501">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam varius sem tortor, nec sodales mi lacinia sit amet.</p>
                      </li>
                      <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/icon-checkbox.png" class="fr-fic fr-dib" data-id="13501">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam varius sem tortor, nec sodales mi lacinia sit amet.</p> 	
                      </li>
                  </ul><a class="button" href="PUT_LINK_HERE">CTA</a></div></div></div>`
        }
      ]
    },
    {
      id: "style-6",
      image: "/assets/style6.png",
      themes: ["all"],
      category: "archive",
      codeblock: [
        {
          title: "No Background",
          code: `<div class="additional-style additional-style-6 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-review-icon">
                      <img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/icon-message-1.png" class="fr-fic fr-dib" data-id="13504">
                  </div>
                  <div class="additional-style-text">
                      <p>“We have over 20 years of experience building car dealer websites for dealerships across the country, so you can be assured that you’re in the hands of experts. Our ethos is to keep things simple and our packages and pricing reflect this.”</p>
      <p class="name">Katie, MD, Spidersnet</p>
                  </div></div></div></div>
      `
        },
        {
          title: "With Background",
          code: `<div class="additional-style additional-style-7 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-review-icon"><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/icon-message-2.png" class="fr-fic fr-dib" data-id="13507"></div>
                  <div class="additional-style-text">
                      <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                      <p class="name">Numan, CEO FN Motors</p>
                  </div></div></div></div>      
      `
        }
      ]
    },
    {
      id: "style-8",
      image: "/assets/style8.png",
      themes: ["all"],
      category: "archive",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-8 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
      <h2>Short Titles Goes here</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam varius sem tortor, nec sodales mi lacinia sit amet.</p>
                      <a href="PUT_LINK_HERE" class="button">CTA</a>
                  </div></div></div>      
      `
        }
      ]
    },
    {
      id: "style-9",
      image: "/assets/style9.png",
      themes: ["all"],
      category: "archive",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-9 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <a href="PUT_LINK_HERE">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
      <img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/icon-right.png" class="fr-fic fr-dii" data-id="13510">
                  </a></div></div></div>         
      `
        }
      ]
    },
    {
      id: "style-10",
      image: "/assets/style10.png",
      themes: ["all"],
      category: "archive",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-10 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-text">
                      <p><strong>You Are in Safe Hands! This dealer has signed our Dealer Promise</strong></p>
                  </div>
      <img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/aa-warranty.png" class="fr-fic fr-dib" data-id="13513"></div></div></div>          
      `
        }
      ]
    },
    {
      id: "style-12",
      image: "/assets/style12.png",
      themes: ["all"],
      category: "archive",
      codeblock: [
        {
          title: "With Background",
          code: `<div class="additional-style additional-style-11 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <p>Proud to be a</p>
      <img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/car-gurus-1.png" class="fr-fic fr-dib" data-id="13516">
      <h3>Top Rated Dealer 2020 & 2021</h3>
      <a href="PUT_LINK_HERE" class="button">CTA</a>
              </div></div></div>      
      `
        },
        {
          title: "No Background",
          code: `<div class="additional-style additional-style-12 clearfix">
          <div class="additional-style-cargurus-top">
              <div class="additional-style-wrap">
                  <div class="additional-style-inner">
                      
                          <p>Proud to be a</p>
                      </div></div></div><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/car-gurus-1.png" class="fr-fic fr-dib" data-id="13516">
                      <div class="additional-style-cargurus-bottom">
             <div class="additional-style-wrap">
                  <div class="additional-style-inner">
                          <h3>Top Rated Dealer 2020 &amp; 2021</h3><a class="button" href="PUT_LINK_HERE">CTA</a></div></div></div></div>
      `
        }
      ]
    },
    {
      id: "style-13",
      title: "5.1",
      image: "/assets/style13.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-13 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <h3>Proud to be a <img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/car-gurus-2.png" class="fr-fic fr-dii" data-id="13519">&nbsp;top rated dealer 2022</h3><a class="button" href="PUT_LINK_HERE">CTA</a></div></div></div>        
      `
        }
      ]
    },
    {
      id: "style-15",
      title: "5.2",
      image: "/assets/style15.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          title: "With Background",
          code: `<div class="additional-style additional-style-15 clearfix">
          <div class="additional-style-cargurus-top">
              <br>
          </div><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/autotrader-1.png" class="fr-fic fr-dib" data-id="13522">
          <div class="additional-style-cargurus-bottom">
              <div class="additional-style-wrap">
                  <p>Highly rated
                      <br>for our customer service 2022</p><a class="button" href="https://www.autotrader.co.uk/INSERT-DEALERS-OWN-AT-URL-HERE #reviews-container">View Reviews</a></div></div></div>  
      `
        },
        {
          title: "No Background",
          code: `<div class="additional-style additional-style-14 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
      <div class="additional-style-logo">
      <img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/autotrader-1.png" class="fr-fic fr-dib" data-id="13522"></div>
                  <p><span>Highly rated</span> for our customer service 2022</p><a class="button" href="PUT_LINK_HERE">CTA</a></div></div></div>      
      `
        }
      ]
    },
    {
      id: "style-16",
      title: "6.1",
      image: "/assets/style16.png",
      themes: ["all"],
      category: "social",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-16 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <h3><span>FOLLOW US ON INSTAGRAM</span> / @spidersnetuk</h3><a class="button" href="PUT_LINK_HERE"target="_blank">Follow</a></div></div></div>      
      `
        }
      ]
    },
    {
      id: "style-17",
      title: "6.2",
      image: "/assets/style17.png",
      themes: ["all"],
      category: "social",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-17 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <h3>Follow Us On Instagram</h3>
                  <div class="instagram-profile">
                      <div class="instagram-profile__left">
                          <div class="instagram-profile__image"><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/icon-right.png" class="fr-fic fr-dii" data-id="13510"></div>
                          <div class="instagram-profile__name">
                              <p><strong>Dealer Name</strong></p>
                              <p>@username</p>
                          </div></div><a class="button" href="PUT_LINK_HERE"target="_blank">Follow</a></div></div></div></div>
      `
        }
      ]
    },
    {
      id: "style-18",
      image: "/assets/style18.png",
      themes: ["all"],
      category: "archive",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-18 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-header">
                      <h3>Read what our customers have to say about us</h3>
                      <div class="rating">
                          <div class="rating-inner"><span>Reviews Rating</span> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"></div></div>
                      <div class="reviews-button"><a class="button" href="PUT_LINK_HERE">View Reviews</a></div></div>
                  <ul class="additional-style-reviews">
                      <li>
                          <div class="additional-style-review">
                              <div class="additional-style-review-icon"><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/icon-message-1.png" class="fr-fic fr-dib" data-id="13504"></div>
                              <div class="additional-style-text">
                                  <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                                  <p class="name">Numan, CEO FN Motors</p>
                              </div></div>
                      </li>
                      <li>
                          <div class="additional-style-review">
                              <div class="additional-style-review-icon"><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/icon-message-1.png" class="fr-fic fr-dib" data-id="13504"></div>
                              <div class="additional-style-text">
                                  <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                                  <p class="name">Numan, CEO FN Motors</p>
                              </div></div>
                      </li>
                      <li>
                          <div class="additional-style-review">
                              <div class="additional-style-review-icon"><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/icon-message-1.png" class="fr-fic fr-dib" data-id="13504"></div>
                              <div class="additional-style-text">
                                  <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                                  <p class="name">Numan, CEO FN Motors</p>
                              </div></div>
                      </li>
                  </ul>
              </div></div></div>      
      `
        }
      ]
    },
    {
      id: "style-19",
      title: "1.2",
      image: "/assets/style19.png",
      themes: ["all"],
      category: "text",
      codeblock: [
        {
          title: "No Background",
          code: `<div class="additional-style additional-style-19 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                        <div class="additional-style-19__image">
                          <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/0d54348d-dd8d-4eca-b3dd-50ba892b4b4f.png" class="fr-fic fr-dib" data-id="56617">
                        </div>
                        <div class="additional-style-19__title">
                          <h3>Looking to quickly sell your car online?</h3>
                        </div>
                        <div class="additional-style-19__text">
                          <p>If you're wondering how to go about selling your car, we're here to help you find the ideal way to maximise the return for your current vehicle.</p>
                        </div>
                        <div class="additional-style-19__button">
                          <a href="PUT_LINK_HERE" class="button">Sell Your Car</a>
                        </div>
              </div>
          </div>
      </div>      
      `
        },
        {
          title: "With Background",
          code: `<div class="additional-style additional-style-19 clearfix" style="background-color: PUT_COLOUR_HERE;">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                        <div class="additional-style-19__image">
                          <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/0d54348d-dd8d-4eca-b3dd-50ba892b4b4f.png" class="fr-fic fr-dib" data-id="56617">
                        </div>
                        <div class="additional-style-19__title">
                          <h3>Looking to quickly sell your car online?</h3>
                        </div>
                        <div class="additional-style-19__text">
                          <p>If you're wondering how to go about selling your car, we're here to help you find the ideal way to maximise the return for your current vehicle.</p>
                        </div>
                        <div class="additional-style-19__button">
                          <a href="PUT_LINK_HERE" class="button">Sell Your Car</a>
                        </div>
              </div>
          </div>
      </div>
      `
        }
      ]
    },
    {
      id: "style-20",
      title: "2.2",
      image: "/assets/style20.png",
      themes: ["all"],
      category: "images",
      codeblock: [
        {
          title: "No Background",
          code: `<div class="additional-style additional-style-20 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-20__title">
                      <h2>Proud To Work With</h2>
                  </div>
                  <div class="additional-style-20__images">
                      <ul>
                          <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                          <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                          <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
      `
        },
        {
          title: "With Background",
          code: `<div class="additional-style additional-style-20 clearfix" style="background-color: PUT_COLOUR_HERE;">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-20__title">
                      <h2>Proud To Work With</h2>
                  </div>
                  <div class="additional-style-20__images">
                      <ul>
                          <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                          <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                          <li><img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/add-text-icon-1.png" class="fr-fic fr-dib" data-id="13498"></li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
      `
        }
      ]
    },
    {
      id: "style-21",
      title: "3.1",
      image: "/assets/style21.png",
      themes: ["all"],
      category: "blocks",
      codeblock: [
        {
          title: "No Background",
          code: `<div class="additional-style additional-style-21 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-text">
                      <h2>Every Vehicle Comes With</h2>
                  </div>
                  <ul>
                      <li>
                          <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/88a2ae33-804a-4588-a181-544581146385.svg" class="fr-fic fr-dib" data-id="56623">
                          <h4>12 Month MOT</h4>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam varius sem tortor, nec sodales mi lacinia sit amet.</p>
                          <p><a href="PUT_LINK_HERE">Learn More</a></p>
                      </li>
                      <li>
                          <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/88a2ae33-804a-4588-a181-544581146385.svg" class="fr-fic fr-dib" data-id="56623">
                          <h4>12 Month MOT</h4>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam varius sem tortor, nec sodales mi lacinia sit amet.</p>
                          <p><a href="PUT_LINK_HERE">Learn More</a></p>
                      </li>
                      <li>
                          <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/88a2ae33-804a-4588-a181-544581146385.svg" class="fr-fic fr-dib" data-id="56623">
                          <h4>12 Month MOT</h4>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam varius sem tortor, nec sodales mi lacinia sit amet.</p> 	
                          <p><a href="PUT_LINK_HERE">Learn More</a></p>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      `
        },
        {
          title: "With Background",
          code: `<div class="additional-style additional-style-21 clearfix" style="background-color: PUT_COLOUR_HERE;>
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-text">
                      <h2><strong>Every Vehicle Comes With</strong></h2>
                  </div>
                  <ul>
                      <li>
                          <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/88a2ae33-804a-4588-a181-544581146385.svg" class="fr-fic fr-dib" data-id="56623">
                          <h4>12 Month MOT</h4>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam varius sem tortor, nec sodales mi lacinia sit amet.</p>
                          <p><a href="PUT_LINK_HERE">Learn More</a></p>
                      </li>
                      <li>
                          <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/88a2ae33-804a-4588-a181-544581146385.svg" class="fr-fic fr-dib" data-id="56623">
                          <h4>12 Month MOT</h4>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam varius sem tortor, nec sodales mi lacinia sit amet.</p>
                          <p><a href="PUT_LINK_HERE">Learn More</a></p>
                      </li>
                      <li>
                          <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/88a2ae33-804a-4588-a181-544581146385.svg" class="fr-fic fr-dib" data-id="56623">
                          <h4>12 Month MOT</h4>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam varius sem tortor, nec sodales mi lacinia sit amet.</p> 	
                          <p><a href="PUT_LINK_HERE">Learn More</a></p>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      `
        },
        {
          title: "Change Icon Background Colour",
          content: "Add to custom css",
          code: `.additional-style.additional-style-21 .additional-style-inner ul li img {
          background-color: PUT_COLOUR_HERE;
          }
      `
        }
      ]
    },
    {
      id: "style-22",
      title: "3.2",
      image: "/assets/style22.png",
      themes: ["all"],
      category: "blocks",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-22 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <ul>
                      <li>
                          <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/88a2ae33-804a-4588-a181-544581146385.svg" class="fr-fic fr-dib" data-id="56623">
                          <h4>12 Month MOT</h4>
                      </li>
                      <li>
                          <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/88a2ae33-804a-4588-a181-544581146385.svg" class="fr-fic fr-dib" data-id="56623">
                          <h4>12 Month MOT</h4>
                      </li>
                      <li>
                          <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/88a2ae33-804a-4588-a181-544581146385.svg" class="fr-fic fr-dib" data-id="56623">
                          <h4>12 Month MOT</h4>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
      `
        },
        {
          title: "Change Icon Background Colour",
          content: "Add to custom css",
          code: `.additional-style.additional-style-22 .additional-style-inner ul li img {
          background-color: PUT_COLOUR_HERE;
          }
      `
        }
      ]
    },
    {
      id: "style-40",
      title: "3.3",
      image: "/assets/style40.png",
      themes: ["all"],
      category: "blocks",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-21 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <ul>
                    <li><img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/11933/websiteMedia/4224/1726500172-tire_repair.svg" class="fr-fic fr-dib" data-id="65532">
                        <h4>Garage Services</h4>
                        <p>Providing a wide array of car servicing options</p>
                    </li>
                    <li><img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/11933/websiteMedia/4224/1726500179-build.svg" class="fr-fic fr-dib" data-id="65535">
                        <h4>Repairs</h4>
                        <p>One-stop destination for professional car repairs</p>
                    </li>
                    <li><img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/11933/websiteMedia/4224/1726500189-inventory.svg" class="fr-fic fr-dib" data-id="65538">
                        <h4>Independent MOT</h4>
                        <p>Proud to offer comprehensive MOT services</p>
                    </li>
                  </ul>
              </div>
          </div>
      </div>
      
      `
        }
      ]
    },
    {
      id: "style-23",
      image: "/assets/style23.png",
      themes: ["all"],
      category: "archive",
      codeblock: [
        {
          title: "No Background",
          code: `<div class="additional-style additional-style-23 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-header">
                      <div class="reviews-title">
                          <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii">
                          <h3>What our customers say about us</h3>
                      </div>
                      <div class="reviews-button"><a class="button" href="PUT_LINK_HERE">View Reviews</a></div></div>
                  <ul class="additional-style-reviews">
                      <li>
                          <div class="additional-style-review">
                              <div class="additional-style-text">
                                  <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                                  <p class="name">Numan, CEO FN Motors</p>
                              </div></div>
                      </li>
                      <li>
                          <div class="additional-style-review">
                              <div class="additional-style-text">
                                  <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                                  <p class="name">Numan, CEO FN Motors</p>
                              </div></div>
                      </li>
                  </ul>
              </div></div></div>      
      `
        },
        {
          title: "With Background",
          code: `<div class="additional-style additional-style-23 clearfix" style="background: #F5F5F5;">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-header">
                      <div class="reviews-title">
                          <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii">
                          <h3>What our customers say about us</h3>
                      </div>
                      <div class="reviews-button"><a class="button" href="PUT_LINK_HERE">View Reviews</a></div></div>
                  <ul class="additional-style-reviews">
                      <li>
                          <div class="additional-style-review">
                              <div class="additional-style-text">
                                  <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                                  <p class="name">Numan, CEO FN Motors</p>
                              </div></div>
                      </li>
                      <li>
                          <div class="additional-style-review">
                              <div class="additional-style-text">
                                  <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                                  <p class="name">Numan, CEO FN Motors</p>
                              </div></div>
                      </li>
                  </ul>
              </div></div></div>       
      `
        },
        {
          title: "Add background colour to review area",
          content: "Add to Custom CSS and replace colours as desired",
          code: `.additional-style.additional-style-23 .additional-style-inner .additional-style-reviews .additional-style-review {
            background: #fff;
            color: #000;
        }`
        },
      ]
    },
    {
      id: "style-24",
      title: "5.3",
      image: "/assets/style24.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-24 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-24__logo">
                      <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/16f50b48-e6d7-48cb-a08b-3b2aba928544.svg" class="fr-fic fr-dib" data-id="56632">
                  </div>
                  <div class="additional-style-24__text">
                      <h3>You are in safe hands!</h3>
                      <p>This dealer has signed our Dealer Promise</p>
                  </div>
                  <div class="additional-style-24__image">
                      <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/2401c765-55e0-42a2-a271-7c3878214c72.png" class="fr-fic fr-dib" data-id="56635">
                  </div>
              </div>
          </div>
      </div>
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">
      `
        }
      ]
    },
    {
      id: "style-25",
      title: "5.4",
      image: "/assets/style25.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-25 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-25__logo">
                      <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/4e896cd7-4315-4226-986d-8dfa400cb419.png" class="fr-fic fr-dib" data-id="56638">
                  </div>
                  <div class="additional-style-25__text">
                      <h3>You are in safe hands!</h3>
                  </div>
                  <div class="additional-style-25__text">
                      <h3 style="font-weight: 300;">This dealer has signed our Dealer Promise</h3>
                  </div>
              </div>
          </div>
      </div>
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">
      `
        }
      ]
    },
    {
      id: "style-26",
      title: "5.5",
      image: "/assets/style26.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          title: "No Background",
          code: `<div class="additional-style additional-style-26 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-26__logo">
                    <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3263/1713191202-logo-cargurus.svg" class="fr-fic fr-dib" data-id="60153" style="max-width:212px;">
                  </div>
                  <div class="additional-style-26__text">
                      <h3><span style="font-weight: 300;">Proud to be a</span>&nbsp;Top Rated Dealer 2022</h3>
                  </div>
                  <div class="additional-style-26__button">
                      <a href="PUT_LINK_HERE" class="button">Read More</a>
                  </div>
              </div>
          </div>
      </div>       
      `
        },
        {
          title: "With Background",
          code: `<div class="additional-style additional-style-26 clearfix" style="background: #F5F5F5;">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-26__logo">
                    <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3263/1713191202-logo-cargurus.svg" class="fr-fic fr-dib" data-id="60153" style="max-width:212px;">
                  </div>
                  <div class="additional-style-26__text">
                      <h3><span style="font-weight: 300;">Proud to be a</span>&nbsp;Top Rated Dealer 2022</h3>
                  </div>
                  <div class="additional-style-26__button">
                      <a href="PUT_LINK_HERE" class="button">Read More</a>
                  </div>
              </div>
          </div>
      </div>      
      `
        }
      ]
    },
    {
      id: "style-27",
      title: "5.6",
      image: "/assets/style27.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-27 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-27__logo">
                  <img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3263/1713191202-logo-cargurus.svg" class="fr-fic fr-dib" data-id="60153" style="max-width:212px;">
                  </div>
                  <div class="additional-style-27__right">
                      <h2><span style="font-weight: 300;">Cargurus 2023</span>&nbsp;Top Rated Dealer</h2>
                      <p>We are proud to be recognized for exceptional customer service!</p>
                  </div>
              </div>
          </div>
      </div>
      `
        }
      ]
    },
    {
      id: "style-28",
      title: "1.3",
      image: "/assets/style28.png",
      themes: ["all"],
      category: "text",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-28 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-bg" style="background: #F5F5F5;">
                      <h2>Looking to quickly sell your car online? You've come to the right place!</h2>
                      <a class="button" href="PUT_LINK_HERE">Read More</a>
                  </div>
              </div>
          </div>
      </div>
      `
        }
      ]
    },
    {
      id: "style-29",
      title: "3.4",
      image: "/assets/style29.png",
      themes: ["all"],
      category: "blocks",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-29 clearfix" style="background-color: #F5F5F5;">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <h2>Every Vehicle Comes With</h2>
                  <ul>
                      <li><img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/88a2ae33-804a-4588-a181-544581146385.svg" class="fr-fic fr-dib" data-id="56623">
                          <h4>12 Month MOT</h4>
                      </li>
                      <li><img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/88a2ae33-804a-4588-a181-544581146385.svg" class="fr-fic fr-dib" data-id="56623">
                          <h4>12 Month MOT</h4>
                      </li>
                      <li><img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/3736/websiteMedia/3475/88a2ae33-804a-4588-a181-544581146385.svg" class="fr-fic fr-dib" data-id="56623">
                          <h4>12 Month MOT</h4>
                      </li>
                  </ul>
              </div></div></div>
      `
        },
        {
          title: "Change Icon Background Colour",
          content: "Add to custom css",
          code: `.additional-style.additional-style-29 .additional-style-inner ul li img {
          background-color: PUT_COLOUR_HERE;
          }
      `
        }
      ]
    },
    {
      id: "style-30",
      title: "5.7",
      image: "/assets/style30.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-30 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-30__image">
                    <img src="https://img.spidersnet.co.uk/code-snippets/yellow-audi.png">
                  </div>
                  <div class="additional-style-30__text">
                      <p>This dealer has committed to</p>
                  </div>
                  <div class="additional-style-30__logo">
                    <img src="https://img.spidersnet.co.uk/code-snippets/aa-cars-standard.svg">
                  </div>
              </div>
          </div>
      </div>
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">
      `
        }
      ]
    },
    {
      id: "style-31",
      title: "5.8",
      image: "/assets/style31.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-31 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-31__logo">
                    <img src="https://img.spidersnet.co.uk/code-snippets/aa-cars.svg">
                  </div>
                  <div class="additional-style-31__text">
                      <h2>Driveaway</h2>
                      <p>Quick and affordable short-term insurance</p>
                  </div>
   
              </div>
          </div>
      </div>
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">
      `
        }
      ]
    },
    {
      id: "style-32",
      title: "5.9",
      image: "/assets/style32.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-32 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-32__logo">
                    <img src="https://img.spidersnet.co.uk/code-snippets/aa-cars.svg">
                  </div>
                  <div class="additional-style-32__text">
                      <h2>Driveaway</h2>
                      <p>Quick and affordable short-term insurance</p>
                  </div>
   
              </div>
          </div>
      </div>
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">
      `
        }
      ]
    },
    {
      id: "style-33",
      title: "5.10",
      image: "/assets/style33.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-33 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-33__image"><img src="https://img.spidersnet.co.uk/code-snippets/autotrader-logo.svg" class="fr-fic fr-dib" data-id="13522"></div>
                  <div class="additional-style-33__title">
                      <h3><span>Highly Rated</span> for our customer service 2023</h3>
                  </div>
                  <div class="additional-style-33__button"><a class="button" href="PUT_LINK_HERE">Read More</a></div></div></div></div>
      `
        }
      ]
    },
    {
      id: "style-34",
      title: "1.4",
      image: "/assets/style34.png",
      themes: ["all"],
      category: "text",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-34 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-34__title">
                      <h3>Looking to quickly sell your car online?</h3>
                  </div>
                  <div class="additional-style-34__text">
                      <p>If you&rsquo;re wondering how to go about selling your car, we&rsquo;re here to help you find the ideal way to maximise the return for your current vehicle.</p>
                  </div>
                  <div class="additional-style-34__button"><a class="button" href="PUT_LINK_HERE">Sell Your Car</a></div></div></div></div>
      `
        }
      ]
    },
    {
      id: "style-35",
      title: "1.5",
      image: "/assets/style35.png",
      themes: ["all"],
      category: "text",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-35 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-35__title">
                      <h3>Looking to quickly sell your car online?</h3>
                  </div>
                  <div class="additional-style-35__text">
                      <p>If you&rsquo;re wondering how to go about selling your car, we&rsquo;re here to help you find the ideal way to maximise the return for your current vehicle.</p>
                      <p><a class="primary-colour-text" href="PUT_LINK_HERE">Sell Your Car</a></p>
                  </div></div></div></div>
      `
        }
      ]
    },
    {
      id: "style-36",
      image: "/assets/style36.png",
      themes: ["all"],
      category: "archive",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-36 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-text"><img src="https://img.spidersnet.co.uk/code-snippets/quote.svg" class="fr-fic fr-dib" data-id="13522">
                      <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                      <p class="name">Numan, CEO FN Motors</p>
                  </div></div></div></div>
      `
        }
      ]
    },
    {
      id: "style-37",
      image: "/assets/style37.png",
      themes: ["all"],
      category: "archive",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-37 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-icon"><img src="https://img.spidersnet.co.uk/code-snippets/quote.svg" class="fr-fic fr-dib" data-id="13522"></div>
                  <div class="additional-style-text">
                      <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                      <p class="name">Numan, CEO FN Motors</p>
                  </div></div></div></div>
      `
        }
      ]
    },
    {
      id: "style-38",
      title: "4.1",
      image: "/assets/style38.png",
      themes: ["all"],
      category: "reviews",
      codeblock: [
        {
          content: "Add to additional text area",
          code: `<div class="additional-style additional-style-38 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <div class="additional-style-header">
                      <div class="reviews-title"><img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii">
                          <h3>What our customers say about us</h3>
                      </div>
                      <div class="reviews-button"><a class="button" href="PUT_LINK_HERE">View Reviews</a></div></div>
                  <ul class="additional-style-reviews">
                      <li>
                          <div class="additional-style-review">
                              <div class="additional-style-text">
                                  <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                                  <p class="name">Numan, CEO FN Motors</p>
                              </div></div>
                      </li>
                      <li>
                          <div class="additional-style-review">
                              <div class="additional-style-text">
                                  <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                                  <p class="name">Numan, CEO FN Motors</p>
                              </div></div>
                      </li>
                      <li>
                          <div class="additional-style-review">
                              <div class="additional-style-text">
                                  <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                                  <p class="name">Numan, CEO FN Motors</p>
                              </div></div>
                      </li>
                  </ul>
              </div></div></div>
      `
        },
        {
          title: "Change quote mark colour",
          content: "Add to custom script",
          code: `.additional-style.additional-style-38 .additional-style-inner .additional-style-reviews .additional-style-review:after {
background: YOUR_COLOUR;
}`
        }
      ]
    },
    {
      id: "style-39",
      image: "/assets/style39.png",
      themes: ["all"],
      category: "archive",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-39 clearfix">
          <div class="additional-style-wrap">
              <div class="additional-style-inner">
                  <ul class="additional-style-reviews">
                      <li>
                          <div class="additional-style-review primary-colour-background">
                              <div class="additional-style-text"><img src="https://img.spidersnet.co.uk/code-snippets/quote-white.svg" class="fr-fic fr-dib fr-fil">
                                  <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                                  <p class="name">Numan, CEO FN Motors</p>
                              </div></div><a class="button" href="PUT_LINK_HERE">Read More</a></li>
                      <li>
                          <div class="additional-style-review primary-colour-background">
                              <div class="additional-style-text"><img src="https://img.spidersnet.co.uk/code-snippets/quote-white.svg" class="fr-fic fr-dib fr-fil">
                                  <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                                  <p class="name">Numan, CEO FN Motors</p>
                              </div></div><a class="button" href="PUT_LINK_HERE">Read More</a></li>
                  </ul>
              </div></div></div>
      `
        }
      ]
    },
    {
      id: "style-41",
      title: "5.11",
      image: "/assets/style41.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-41 clearfix">
    <div class="additional-style-wrap">
        <div class="additional-style-inner">
            <div class="additional-style-41__logo">
                <img src="https://img.spidersnet.co.uk/code-snippets/rac-warranty.svg">
            </div>
            <div class="additional-style-41__arrows">
                <img src="https://img.spidersnet.co.uk/code-snippets/rac-arrow-orange.png">
            </div>
            <div class="additional-style-41__element">
                    <h3>Drive away with&nbsp;<span>peace of mind</span></h3>
                    <p>Our simple claims procedure means vehicles are back on the road as soon as possible</p>
             </div>
        </div>
    </div>
</div>
<link rel="stylesheet" href="https://use.typekit.net/xhn1zlo.css">
      `
        }
      ]
    },
    {
      id: "style-42",
      title: "5.12",
      image: "/assets/style42.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-42 clearfix">
    <div class="additional-style-wrap">
        <div class="additional-style-inner">
            <div class="additional-style-42__logo">
                <img src="https://img.spidersnet.co.uk/code-snippets/rac-warranty.svg">
            </div>
            <div class="additional-style-42__arrows">
                <img src="https://img.spidersnet.co.uk/code-snippets/rac-arrow-grey.png">
            </div>
            <div class="additional-style-42__element">
                    <h3>Drive away with peace of mind</h3>
                    <p>Our simple claims procedure means vehicles are back on the road as soon as possible</p>
             </div>
        </div>
    </div>
</div>
<link rel="stylesheet" href="https://use.typekit.net/xhn1zlo.css">
      `
        }
      ]
    },
    {
      id: "style-43",
      title: "5.13",
      image: "/assets/style43.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-43 clearfix">
    <div class="additional-style-wrap">
        <div class="additional-style-inner">
            <div class="additional-style-43__logo">
                <img src="https://img.spidersnet.co.uk/code-snippets/rac-approved.svg">
            </div>
            <div class="additional-style-43__arrows">
                <img src="https://img.spidersnet.co.uk/code-snippets/rac-arrow-orange.png">
            </div>
            <div class="additional-style-43__element">
                    <h3>Proud to be an <span>RAC Approved Dealer</span></h3>
             </div>
        </div>
    </div>
</div>
<link rel="stylesheet" href="https://use.typekit.net/xhn1zlo.css">
      `
        }
      ]
    },
    {
      id: "style-44",
      title: "5.14",
      image: "/assets/style44.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-44 clearfix">
    <div class="additional-style-wrap">
        <div class="additional-style-inner">
            <div class="additional-style-44__logo">
                <img src="https://img.spidersnet.co.uk/code-snippets/rac-approved.svg">
            </div>
            <div class="additional-style-44__divider"></div>
            <div class="additional-style-44__element">
                    <ul>
                        <li>Minimum 12 months RAC Warranty</li>
                        <li>12 months free RAC Roadside Cover*</li>
                        <li>Prepared to the RAC 82 point Approved Vehicle Standard</li>
                        <span>*Vehicle-based Roadside Cover</span>
                    </ul>
             </div>
        </div>
    </div>
</div>
<link rel="stylesheet" href="https://use.typekit.net/xhn1zlo.css">
      `
        }
      ]
    },
    {
      id: "style-45",
      title: "5.15",
      image: "/assets/style45.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-45 clearfix">
    <div class="additional-style-wrap">
        <div class="additional-style-inner">
            <div class="additional-style-45__logo">
                <img src="https://img.spidersnet.co.uk/code-snippets/rac-approved.svg">
            </div>
            <div class="additional-style-45__divider"></div>
            <div class="additional-style-45__element">
                    <ul>
                        <li>Minimum 12 months RAC Warranty</li>
                        <li>12 months free RAC Roadside Cover*</li>
                        <li>Prepared to the RAC 82 point Approved Vehicle Standard</li>
                        <span>*Vehicle-based Roadside Cover</span>
                    </ul>
             </div>
        </div>
    </div>
</div>
<link rel="stylesheet" href="https://use.typekit.net/xhn1zlo.css">
      `
        }
      ]
    },
    {
      id: "style-46",
      title: "5.16",
      image: "/assets/style46.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-46 clearfix">
    <div class="additional-style-wrap">
        <div class="additional-style-inner">
            <div class="additional-style-46__logo">
                <img src="https://img.spidersnet.co.uk/code-snippets/rac-approved.svg">
            </div>
            <div class="additional-style-46__divider"></div>
            <div class="additional-style-46__element">
                    <h3>Why an RAC Warranty?</h3>
                    <ul>
                        <li>12 months free breakdown cover*</li>
                        <li>No excess to pay</li>
                        <li>Monthly payments to spread costsd</li>
                        <span>*May require activation</span>
                    </ul>
             </div>
        </div>
    </div>
</div>
<link rel="stylesheet" href="https://use.typekit.net/xhn1zlo.css">
      `
        }
      ]
    },
    {
      id: "style-47",
      title: "5.17",
      image: "/assets/style47.png",
      themes: ["all"],
      category: "partners",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-47 clearfix">
    <div class="additional-style-wrap">
        <div class="additional-style-inner">
            <div class="additional-style-47__logo">
                <img src="https://img.spidersnet.co.uk/code-snippets/rac-approved.svg">
            </div>
            <div class="additional-style-47__divider"></div>
            <div class="additional-style-47__element">
                    <h3>Why an RAC Warranty?</h3>
                    <ul>
                        <li>12 months free breakdown cover*</li>
                        <li>No excess to pay</li>
                        <li>Monthly payments to spread costsd</li>
                        <span>*May require activation</span>
                    </ul>
             </div>
        </div>
    </div>
</div>
<link rel="stylesheet" href="https://use.typekit.net/xhn1zlo.css">
      `
        }
      ]
    },
    {
      id: "style-48",
      title: "4.2",
      image: "/assets/style48.png",
      themes: ["all"],
      category: "reviews",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-48 clearfix">
  <div class="additional-style-wrap">
      <div class="additional-style-inner">
          <div class="additional-style-48__left">
            <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii"> <img src="/basemedia/star.svg" class="fr-fic fr-dii">
            <h3>Customer Reviews</h3>
            <p>See what our customers have to say...</p>
          </div>
          <div class="additional-style-48__right">
              <a href="PUT_LINK_HERE" target="_blank"><img src="https://img.spidersnet.co.uk/code-snippets/google-button.svg"></a>
              <a href="PUT_LINK_HERE" target="_blank"><img src="https://img.spidersnet.co.uk/code-snippets/autotrader-button.svg"></a>
              <a href="PUT_LINK_HERE" target="_blank"><img src="https://img.spidersnet.co.uk/code-snippets/facebook-button.svg"></a>
           </div>
      </div>
  </div>
</div>
      `
        }
      ]
    },
    {
      id: "style-49",
      title: "4.3",
      image: "/assets/style49.png",
      themes: ["all"],
      category: "reviews",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-49 clearfix">
  <div class="additional-style-wrap">
      <div class="additional-style-inner">
          <div class="additional-style-49__block">
            <div class="review-logo"><img src="https://img.spidersnet.co.uk/code-snippets/google-logo.svg"></div>
            <p class="stars">&#9733; &#9733; &#9733; &#9733; &#9733;</p>
            <p class="rating">Rated <span>4.8</span> out of 5</p>
            <a href="PUT_LINK_HERE" target="_blank" class="button">Read More</a>
          </div>
          <div class="additional-style-49__block">
            <div class="review-logo"><img src="https://img.spidersnet.co.uk/code-snippets/at-logo.svg"></div>
            <p class="stars">&#9733; &#9733; &#9733; &#9733; &#9733;</p>
            <p class="rating">Rated <span>4.8</span> out of 5</p>
            <a href="PUT_LINK_HERE" target="_blank" class="button">Read More</a>
          </div>
          <div class="additional-style-49__block">
            <div class="review-logo"><img src="https://img.spidersnet.co.uk/code-snippets/facebook-logo.svg"></div>
            <p class="stars">&#9733; &#9733; &#9733; &#9733; &#9733;</p>
            <p class="rating">Rated <span>4.8</span> out of 5</p>
            <a href="PUT_LINK_HERE" target="_blank" class="button">Read More</a>
          </div>
      </div>
  </div>
</div>
      `
        }
      ]
    },
    {
      id: "style-50",
      title: "4.4",
      image: "/assets/style50.png",
      themes: ["all"],
      category: "reviews",
      codeblock: [
        {
          code: `          <div class="additional-style additional-style-50 clearfix">
            <div class="additional-style-wrap">
                <div class="additional-style-half">
                  <div class="additional-style-review">
                    <div class="additional-style-text">
                      <div class="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
                        <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                        <p class="name">Numan, CEO FN Motors</p>
                    </div></div>
                </div>
                <div class="additional-style-half">
                  <h3>What People Think About Us</h3>
                  <a href="/reviews/" class="button">Read More</a>
                  <div class="rating-wrap">
                    <div class="rating">
                        <span>4.8</span>
                          <div class="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
                    </div>
                    <div class="images">
                      <img src="https://img.spidersnet.co.uk/code-snippets/award-placeholder.png">
                      <img src="https://img.spidersnet.co.uk/code-snippets/award-placeholder.png">
                      <img src="https://img.spidersnet.co.uk/code-snippets/award-placeholder.png">
                    </div>
                  </div>
              </div>
            </div>
          </div>
      `
        }
      ]
    },
    {
      id: "style-51",
      title: "3.5",
      image: "/assets/style51.png",
      themes: ["all"],
      category: "blocks",
      codeblock: [
        {
          code: `        <div class="additional-style additional-style-51 clearfix">
            <div class="additional-style-wrap">
                <div class="additional-style-inner">
                    <div class="additional-style-text">
                        <h4>Why Choose Us</h4>
                        <h2>Company Name</h2>
                        <p>Experience premium automotive excellence with our comprehensive services and exceptional customer care.</p>
                    </div>
                    <ul>
                        <li>
                            <img src="https://img.spidersnet.co.uk/code-snippets/circle-check-regular-white.svg">
                            <div class="block-content">
                              <h3>FCA Registered</h3>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </div>
                        </li>
                        <li>
                          <img src="https://img.spidersnet.co.uk/code-snippets/circle-check-regular-white.svg">
                            <div class="block-content">
                              <h3>Great choice of premium brands</h3>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </div>
                        </li>
                        <li>
                          <img src="https://img.spidersnet.co.uk/code-snippets/circle-check-regular-white.svg">
                            <div class="block-content">
                              <h3>12 Month MOT</h3>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </div>
                        </li>
                        <li>
                          <img src="https://img.spidersnet.co.uk/code-snippets/circle-check-regular-white.svg">
                          <div class="block-content">
                            <h3>12 Month MOT</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                          </div>
                      </li>
                      <li>
                        <img src="https://img.spidersnet.co.uk/code-snippets/circle-check-regular-white.svg">
                        <div class="block-content">
                          <h3>12 Month MOT</h3>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </div>
                      </li>
                      <li>
                        <img src="https://img.spidersnet.co.uk/code-snippets/circle-check-regular-white.svg">
                        <div class="block-content">
                          <h3>12 Month MOT</h3>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </div>
                    </li>
                    </ul>
                </div>
            </div>
        </div>
      `
        },
        {
          title: "Colour changes",
          content: "Add to custom css",
          code: `.additional-style.additional-style-51 {
                    background: BACKGROUND_COLOUR;
                }
                .additional-style.additional-style-51 .additional-style-inner ul li {
                  background: BLOCK_BACKGROUND_COLOUR;
                }
                .additional-style.additional-style-51 h2, .additional-style.additional-style-51 h3, .additional-style.additional-style-51 h4 {
                    color: TITLE_COLOUR;
                    border-color: TITLE_COLOUR;
                }
                .additional-style.additional-style-51 {
                  color: TEXT_COLOUR;
                }
        `
        }
      ]
    },
     {
      id: "style-52",
      title: "4.5",
      image: "/assets/style52.png",
      themes: ["all"],
      category: "reviews",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-52 clearfix">
            <div class="additional-style-wrap">
              <div class="additional-style-title">
                <h3>See What Our Customers Are Saying</h3>
                <a href="/reviews/" class="read-reviews">Read Reviews</a>
              </div>
            </div>
            <div class="additional-style-wrap">
                <div class="additional-style-half">
                  <div class="additional-style-review">
                    <div class="additional-style-text">
                      <div class="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
                        <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                        <p class="name">Numan, CEO FN Motors</p>
                    </div></div>
                </div>
                <div class="additional-style-half">
                  <div class="additional-style-review">
                    <div class="additional-style-text">
                      <div class="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
                        <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                        <p class="name">Numan, CEO FN Motors</p>
                    </div></div>
                </div>
            </div>
          </div>
      `
        },
        {
        title: 'Change background colour',
          content: 'Custom CSS',
          code: `.additional-style.additional-style-52 {
    background-color: BACKGROUND_COLOUR;
}
      `
        }
      ]
    },
    {
      id: "style-53",
      title: "4.6",
      image: "/assets/style53.png",
      themes: ["all"],
      category: "reviews",
      codeblock: [
        {
          code: ` <div class="additional-style additional-style-53 clearfix">
            <div class="additional-style-wrap">

                <div class="additional-style-half">
         
                    <div class="rating">
                        <span>4.8</span>
                          <div class="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
                    </div>
                    <div class="images">
                      <img src="https://img.spidersnet.co.uk/code-snippets/award-placeholder.png">
                      <img src="https://img.spidersnet.co.uk/code-snippets/award-placeholder.png">
                      <img src="https://img.spidersnet.co.uk/code-snippets/award-placeholder.png">
                    </div>
           
              </div>
              <div class="additional-style-half">
                <div class="additional-style-review">
                  <div class="additional-style-text">
                    <div class="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
                    <h3>Customer Reviews</h3>
                      <p>&ldquo;Our fantastic team are passionate about delivering outstanding customer experiences and our mission is to ensure that you love both the FN car buying experience and the car&rdquo;</p>
                      <p class="name">Numan, CEO FN Motors</p>
                      <a href="/reviews/" class="button">Read More</a>
                  </div></div>
              </div>
            </div>
          </div>
      `
        }
      ]
    },
    {
      id: "style-54",
      title: "4.7",
      image: "/assets/style54.png",
      themes: ["all"],
      category: "reviews",
      codeblock: [
        {
          code: `          <div class="additional-style additional-style-54 clearfix">
            <div class="additional-style-wrap">

                <div class="additional-style-inner">
         
                  <div class="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</div>
                  <h3>Customer Reviews</h3>
                  <p>See what our customers have to say...</p>
                  <div class="logos">
                    <a href="PUT_LINK_HERE" target="_blank"><img src="https://img.spidersnet.co.uk/code-snippets/google-button.svg"></a>
                    <a href="PUT_LINK_HERE" target="_blank"><img src="https://img.spidersnet.co.uk/code-snippets/autotrader-button.svg"></a>
                    <a href="PUT_LINK_HERE" target="_blank"><img src="https://img.spidersnet.co.uk/code-snippets/facebook-button.svg"></a>
                  </div>
              </div>
            </div>
          </div>
      `
        }
      ]
    },
    {
      id: "style-55",
      title: "6.3",
      image: "/assets/style55.png",
      themes: ["all"],
      category: "social",
      codeblock: [
        {
          code: `<div class="additional-style additional-style-55 clearfix">
    <div class="additional-style-wrap">
        <div class="additional-style-inner">
            <div>
                <h3>Stay Updated</h3>
                <h3 class="light">Follow Us Today!</h3>
            </div>
            <div>
                <a href="https://www.tiktok.com/@perfomancevaleting" rel="noopener noreferrer" target="_blank"><img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/12411/websiteMedia/4757/1741099266-tiktok.png" class="fr-fic fr-dii" data-id="71399"></a>
                <a href="https://www.facebook.com/p/Performance-Valeting-100069518736967/" rel="noopener noreferrer" target="_blank"><img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/12411/websiteMedia/4757/1741099226-facebook.png" class="fr-fic fr-dii" data-id="71393"></a>
                <a href="https://www.instagram.com/performance__valeting/" rel="noopener noreferrer" target="_blank"><img src="https://s3-eu-west-1.amazonaws.com/files.autopromotor/12411/websiteMedia/4757/1741099250-instagram.png" class="fr-fic fr-dii" data-id="71396"></a></div></div></div></div>
      `
        },
        {
          title: "Change colours",
          content: "Add to custom css",
          code: `.additional-style.additional-style-55 {
    background: BACKGROUND_COLOR;
    border-color: BOTTOM_BORDER_COLOR;
}
.additional-style.additional-style-social h3.light {
    color: PRIMARY_COLOR;
}
      `
        }
      ]
    },
    {
      id: "slide-1",
      image: "/assets/slide1.png",
      themes: ["premier-1"],
      category: "slides",
      title: "Premier 1",
      codeblock: [
        {
          title: "Default (Centre aligned)",
          code: `<div class="hero-caption">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        },
        {
          title: "Right aligned",
          code: `<div class="hero-caption right">
              <h2>Buy Your Next Car From The Comfort of Your Home</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
          </div>`
        },
        {
          title: "Left aligned",
          code: `<div class="hero-caption left">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        }
      ]
    },
    {
      id: "slide-2",
      image: "/assets/slide2.png",
      themes: ["premier-2"],
      category: "slides",
      title: "Premier 2",
      codeblock: [
        {
          title: "Default (Left aligned)",
          code: `<div class="hero-caption">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        },
        {
          title: "Right aligned",
          code: `<div class="hero-caption right">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        },
        {
          title: "Centre aligned",
          code: `<div class="hero-caption center">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        }
      ]
    },
    {
      id: "slide-3",
      image: "/assets/slide3.png",
      themes: ["premier-3"],
      category: "slides",
      title: "Premier 3",
      codeblock: [
        {
          code: `<div class="hero-caption">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        }
      ]
    },
    {
      id: "slide-4",
      image: "/assets/slide4.png",
      themes: ["premier-4"],
      category: "slides",
      title: "Premier 4",
      codeblock: [
        {
          title: "Default (Left aligned)",
          code: `<div class="hero-caption">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        },
        {
          title: "Right aligned",
          code: `<div class="hero-caption right">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        },
        {
          title: "Centre aligned",
          code: `<div class="hero-caption center">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        }
      ]
    },
    {
      id: "slide-5",
      image: "/assets/slide5.png",
      themes: ["premier-5"],
      category: "slides",
      title: "Premier 5",
      codeblock: [
        {
          title: "Default (Left aligned)",
          code: `<div class="hero-caption">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        },
        {
          title: "Right aligned",
          code: `<div class="hero-caption right">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        },
        {
          title: "Centre aligned",
          code: `<div class="hero-caption center">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        }
      ]
    },
    {
      id: "slide-6",
      image: "/assets/slide6.png",
      themes: ["premier-6"],
      category: "slides",
      title: "Premier 6",
      codeblock: [
        {
          title: "Default (Left aligned)",
          code: `<div class="hero-caption">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        },
        {
          title: "Right aligned",
          code: `<div class="hero-caption right">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        },
        {
          title: "Centre aligned",
          code: `<div class="hero-caption center">
          <h2>Buy Your Next Car From The Comfort of Your Home</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
      </div>`
        }
      ]
    },
    {
      id: "slide-7",
      image: "/assets/slide7.png",
      themes: ["premier-7"],
      category: "slides",
      title: "Premier 7",
      codeblock: [
        {
          title: "Default (line above and below)",
          code: `<div class="hero-caption style">
          <h2>We can help you to</h2>
          <p>Finance your next car</p>
          </div>`
        },
        {
          title: "Right aligned",
          code: `<div class="hero-caption style right">
          <h2>We can help you to</h2>
          <p>Finance your next car</p>
          </div>`
        },
        {
          title: "Centre aligned",
          code: `<div class="hero-caption style center">
          <h2>We can help you to</h2>
          <p>Finance your next car</p>
          </div>`
        },
        {
          title: "Plain",
          code: `<div class="hero-caption">
          <h2>We can help you to</h2>
          <p>Finance your next car</p>
          </div>`
        },
        {
          title: "With Icon",
          code: `<div class="hero-caption style icon">
          <img src="https://s3.eu-west-1.amazonaws.com/processed-files.autopromotor.co.uk/shared/assets/hero-icon-p7.png" class="fr-fic fr-dib" data-id="13495">
              <h2>Buy Your Next Car From The Comfort of Your Home</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
          </div>`
        },
        {
          title: "With title, text and button",
          code: `<div class="hero-caption style">
    <h2>Find your next van today</h2>
    <p>Check out our latest used vans for sale</p>
    <a style="margin-top: 20px; margin-bottom: 10px;"class="button" href="/used/vans/">View Stock</a>
</div>`
        }
      ]
    },
    {
      id: "slide-10",
      image: "/assets/slide10.png",
      themes: ["premier-10"],
      category: "slides",
      title: "Premier 10",
      codeblock: [
        {
          code: `<div class="hero-caption">
<p>Welcome to Spidersnet</p>
<h2>We can help you find your perfect car</h2>
<p><a class="button" href="/used/cars/" tabindex="0">View Stock</a></p>
</div>`
        }
      ]
    },
    {
      id: "slide-11",
      image: "/assets/slide11.png",
      themes: ["premier-11"],
      category: "slides",
      title: "Premier 11",
      codeblock: [
        {
          code: `<h2>Best Choice of Body Kit Exhaust</h2>
<h3>New Arrivals</h3>
<p><a class="button" href="/used/cars/">Stocklist</a></p>`
        }
      ]
    },
    {
      id: "slide-essential",
      image: "/assets/slide-essential.png",
      themes: ["essential-1","essential-2","essential-3","essential-4"],
      category: "slides",
      title: "Essentials",
      codeblock: [
        {
          title: "Default (Centre aligned)",
          code: `<div class="hero-caption">
              <h2>Buy Your Next Car From The Comfort of Your Home</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
          </div>`
        },
        {
          title: "Right aligned",
          code: `<div class="hero-caption right">
              <h2>Buy Your Next Car From The Comfort of Your Home</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
          </div>`
        },
        {
          title: "Left aligned",
          code: `<div class="hero-caption left">
              <h2>Buy Your Next Car From The Comfort of Your Home</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
          </div>`
        }
      ],
    }
  ];
